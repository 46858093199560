export interface Data {
  plan: Plan;
}

export interface PlanValidationError {
  field: string;
  message: string;
  row: number;
}

export interface ComparePlan {
  diff: string[];
  diffType: string;
  id: string;
  old: KeyValue[];
  new: KeyValue[];
}

export interface ValidatePlan {
  errors: PlanValidationError[];
}

export interface ValidatePlanResponse {
  validatePlan: ValidatePlan;
}

export interface ComparePlanResponse {
  comparePlan: ComparePlan[];
}

export interface userRecordsResponse {
  userRecords: string;
}

export interface MainPlanResponse {
  mainPlan: string;
}

export interface KeyValue {
  field: string;
  value: string;
}

export interface Param {
  name: string;
  options: string[];
  type: DialogInput;
  value: string;
}

export interface PlanResponse {
  plan: Plan;
}

export interface User {
  email: string;
  firstName: string;
  lastName: string;
}

export interface PlanReview {
  action: ReviewAction;
  reason: string;
  reviewer: User;
  submitter: User;
}

export interface Plan {
  id: string;
  type: string;
  status: string;
  review: PlanReview;
}

export enum DialogInput {
  DROPDOWN = "DROPDOWN",
  SINGLELNE = "SINGLELINE",
  MULTILINE = "MULTILINE",
}

export enum PlanType {
  DIGITAL_READINESS = "Site Digital Readiness",
  FDX_HHP = "FDX HHP Target",
  GENESIS_HHP = "Genesis HHP Target",
  SITE_TIER = "Site Tier",
  POR = "EK's POR",
}

export enum ReviewAction {
  APPROVE = "APPROVE",
  DENY = "DENY",
  REVIEW = "REVIEW",
}
